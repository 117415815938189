import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Typography,
  Button,
  MenuItem,
  Popover,
  TableSortLabel,
  TextField,
  Grid
} from '@mui/material';
import { getMembershipMemberList } from '../../api';
import useFetch from '../../hooks/useFetch';
import { useNavigate } from 'react-router-dom';

const generateShortHash = (data) => {
  if (!data) return '';
  let hash = 0;
  for (let i = 0; i < data.length; i++) {
    hash = (hash << 5) - hash + data[i].patient_id;
    hash |= 0;
  }
  return hash;
};

const MemberList = ({ username, token }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('appointment_date');
  const [market, setMarket] = useState('All');
  const [insurance, setInsurance] = useState('All');
  const [category, setCategory] = useState('All');
  const [source, setSource] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFilter, setActiveFilter] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [filterOptions, setFilterOptions] = useState({ markets: [], insurances: [], categories: [], sources: [] });

  const { data, loading, error } = useFetch(getMembershipMemberList, {
    username,
    token,
    page,
    limit: rowsPerPage,
    market,
    insurance,
    category,
    source,
    sortBy: orderBy,
    sortDirection: order,
    searchTerm,
    refreshTrigger,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data?.filters) {
      setFilterOptions({
        markets: data.filters.markets,
        insurances: data.filters.insurances,
        categories: data.filters.categories,
        sources: data.filters.sources,
      });
    }
  }, [data]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const handleFilterClick = (event, filterType) => {
    setAnchorEl(event.currentTarget);
    setActiveFilter(filterType);
  };

  const handleFilterSelect = (value) => {
    if (activeFilter === 'markets') setMarket(value);
    if (activeFilter === 'insurances') setInsurance(value);
    if (activeFilter === 'categories') setCategory(value);
    if (activeFilter === 'sources') setSource(value);
    setAnchorEl(null);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleResetFilters = () => {
    setMarket('All');
    setInsurance('All');
    setCategory('All');
    setSource('All');
    setSearchTerm('');
    setPage(0);
  };

  if (loading) return <Typography variant="h6" align="center">Loading...</Typography>;
  if (error) return <Typography variant="h6" align="center">Error: {error.message}</Typography>;

  return (
    <Paper sx={{ padding: 2, marginTop: 2 }}>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={10}>
          <TextField
            fullWidth
            variant="outlined"
            label="Search by Patient ID, Name"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="secondary" fullWidth onClick={handleResetFilters}>
            Reset Filters
          </Button>
        </Grid>
      </Grid>
    
      <TableContainer key={generateShortHash(data?.members || [])}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Patient ID</TableCell>
              <TableCell>Patient Name</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>
                <Button  sx={{ fontWeight: source !== 'All' ? 'bold' : 'normal' }} onClick={(e) => handleFilterClick(e, 'sources')}>Source</Button>
              </TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Close Date</TableCell>
              <TableCell>Opportunity ID</TableCell>
              <TableCell>
                <Button  sx={{ fontWeight: market !== 'All' ? 'bold' : 'normal' }} onClick={(e) => handleFilterClick(e, 'markets')}>Market</Button>
              </TableCell>
              <TableCell>
                <Button  sx={{ fontWeight: insurance !== 'All' ? 'bold' : 'normal' }} onClick={(e) => handleFilterClick(e, 'insurances')}>Insurance</Button>
              </TableCell>
              <TableCell>
                <Button  sx={{ fontWeight: category !== 'All' ? 'bold' : 'normal' }} onClick={(e) => handleFilterClick(e, 'categories')}>Category</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.members?.map((row) => (
              <TableRow key={row.patient_id}>
                <TableCell>{row.patient_id}</TableCell>
                <TableCell>{row.patient_name}</TableCell>
                <TableCell>{row.dob}</TableCell>
                <TableCell>{row.first_contact_source}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.close_date}</TableCell>
                <TableCell>{row.opportunity_id}</TableCell>
                <TableCell>{row.market}</TableCell>
                <TableCell>{row.insurance}</TableCell>
                <TableCell>{row.category}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    
      <TablePagination
        component="div"
        count={data?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Popover component for filter options */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {filterOptions[activeFilter]?.length > 0 ? (
          filterOptions[activeFilter].map((option) => (
            <MenuItem key={option} onClick={() => handleFilterSelect(option)}>
              {option}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Popover>
    </Paper>
  
  );
};

export default MemberList;