import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (credential) => {
  try {
    const response = await axios.post(`${API_URL}/apollo/login`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const resetPassword = async (credential) => {
  try {
    const response = await axios.post(`${API_URL}/apollo/reset_password`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const getAuth = async (credential) => {
  try {
    const response = await axios.post(`${API_URL}/apollo/auth`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const getApolloHeader = async (patientId, credential) => {
  try {
    const response = await axios.post(`${API_URL}/apollo/patient_header/${patientId}`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const setApolloHeaderStatus = async (patientId, credential, status) => {
  try {
    let postData = credential;
    postData['module_category'] = 'Header';
    postData['module_name'] = 'Basic';
    postData['module_item_key'] = patientId;
    postData['module_item_status'] = status;

    const response = await axios.post(`${API_URL}/apollo/patient_module_status/${patientId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const getApolloUtilization = async (patientId, credential) => {
  try {
    const response = await axios.post(`${API_URL}/apollo/patient_utilization/${patientId}`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const getApolloDiseaseDetection = async (patientId, credential) => {
  try {
    const response = await axios.post(`${API_URL}/apollo/patient_disease_detection/${patientId}`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const setApolloDiseaseDetectionGapStatus = async (patientId, credential, gap, status) => {
  try {
    let postData = credential;
    postData['module_category'] = 'DiseaseDetection';
    postData['module_name'] = 'HccGaps';
    postData['module_item_key'] = gap;
    postData['module_item_status'] = status;
    const response = await axios.post(`${API_URL}/apollo/patient_module_status/${patientId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const setApolloDiseaseDetectionRecStatus = async (patientId, credential, rec, status) => {
  try {
    let postData = credential;
    postData['module_category'] = 'DiseaseDetection';
    postData['module_name'] = 'HccRecommendations';
    postData['module_item_key'] = rec;
    postData['module_item_status'] = status;
    const response = await axios.post(`${API_URL}/apollo/patient_module_status/${patientId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const getApolloMedicationAdherence = async (patientId, credential) => {
  try {
    const response = await axios.post(`${API_URL}/apollo/patient_medication_adherence/${patientId}`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const setApolloMedicationAdherenceStatus = async (patientId, credential, medicationName, status) => {
  try {
    let postData = credential;
    postData['module_category'] = 'MedicationAdherence';
    postData['module_name'] = 'MedicationAdherence';
    postData['module_item_key'] = medicationName;
    postData['module_item_status'] = status;

    const response = await axios.post(`${API_URL}/apollo/patient_module_status/${patientId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const getApolloQualityDiabetes = async (patientId, credential) => {
  try {
    const response = await axios.post(`${API_URL}/apollo/patient_quality_diabetes/${patientId}`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const setApolloQualityDiabetesStatus = async (patientId, credential, measure, hedisYear, status) => {
  try {
    let postData = credential;
    postData['module_category'] = 'QualityDiabetes';
    postData['module_name'] = measure;
    postData['module_item_key'] = hedisYear;
    postData['module_item_status'] = status;

    const response = await axios.post(`${API_URL}/apollo/patient_module_status/${patientId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const getApolloQualityScreening = async (patientId, credential) => {
  try {
    const response = await axios.post(`${API_URL}/apollo/patient_quality_screening/${patientId}`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const setApolloQualityScreeningStatus = async (patientId, credential, measure, hedisYear, status) => {
  try {
    let postData = credential;
    postData['module_category'] = 'QualityScreening';
    postData['module_name'] = measure;
    postData['module_item_key'] = hedisYear;
    postData['module_item_status'] = status;

    const response = await axios.post(`${API_URL}/apollo/patient_module_status/${patientId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const getApolloScreeners = async (patientId, credential) => {
  try {
    const response = await axios.post(`${API_URL}/apollo/patient_screeners/${patientId}`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const setApolloScreenersStatus = async (patientId, credential, screeners_name, screeners_key, status) => {
  try {
    let postData = credential;
    postData['module_category'] = 'Screeners';
    postData['module_name'] = screeners_name;
    postData['module_item_key'] = screeners_key;
    postData['module_item_status'] = status;

    const response = await axios.post(`${API_URL}/apollo/patient_module_status/${patientId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const getApolloReferral = async (patientId, credential) => {
  try {
    const response = await axios.post(`${API_URL}/apollo/patient_referral/${patientId}`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const setApolloReferralStatus = async (patientId, credential, referralID, status) => {
  try {
    let postData = credential;
    postData['module_category'] = 'Referral';
    postData['module_name'] = 'PendingReferral';
    postData['module_item_key'] = referralID;
    postData['module_item_status'] = status;

    const response = await axios.post(`${API_URL}/apollo/patient_module_status/${patientId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const sendApolloFeedback = async (patientId, credential, feedbackData) => {
  try {
    let postData = credential;
    postData['module_category'] = feedbackData['module_category'];
    postData['module_name'] = feedbackData['module_name'];
    postData['module_item_key'] = feedbackData['module_item_key'];
    postData['module_item_feedback'] = feedbackData['feedback_description'];
    const response = await axios.post(`${API_URL}/apollo/patient_feedback/${patientId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const addApolloDiseaseDetection = async (patientId, credential, diagnosis, note, timestampString) => {
  try {
    let postData = credential;
    postData['module_category'] = 'DiseaseDetection';
    postData['module_name'] = 'Coding';
    postData['module_item_key'] = diagnosis + "/" + timestampString;
    postData['module_item_object'] = {
      "PatientID": patientId,
      "DiagnosisCode": diagnosis.split('/')[0],
      "HCC": diagnosis.split('/')[1].split(' - ')[0] || "",
      "Description": note
    };
    const response = await axios.post(`${API_URL}/apollo/patient_add_disease_detection/${patientId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};