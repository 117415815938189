import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getCodingTaskQueue = async (credential) => {
    try {
      const response = await axios.post(`${API_URL}/coding/task_queue`, credential);
      return response.data;
    } catch (error) {
      console.error('Error fetching patient:', error);
      throw error;
    }
  };

export const getCodingPatientList = async (credential) => {
    try {
      const response = await axios.post(`${API_URL}/coding/patient_list`, credential);
      return response.data;
    } catch (error) {
      console.error('Error fetching patient:', error);
      throw error;
    }
  };

export const getCodingPatientDetails = async (credential) => {
    try {
      const response = await axios.post(`${API_URL}/coding/patient_details`, credential);
      return response.data;
    } catch (error) {
      console.error('Error fetching patient:', error);
      throw error;
    }
  };

export const setCodingPatientTaskReview = async (credential, task, review_result, review_comment, expiration_at) => {
    try {
      credential['patient_id'] = task['patient_id'];
      credential['task_id'] = task['task_id'];
      credential['review_result'] = review_result;
      credential['review_comment'] = review_comment;
      credential['expiration_dt'] = expiration_at;
      const response = await axios.post(`${API_URL}/coding/task_review`, credential);
      return response.data;
    } catch (error) {
      console.error('Error fetching patient:', error);
      throw error;
    }
  };

export const getCodingPatientTaskReviews = async (credential, taskId) => {
    try {
      credential['task_id'] = taskId;
      const response = await axios.post(`${API_URL}/coding/task_review_history`, credential);
      return response.data;
    } catch (error) {
      console.error('Error fetching patient:', error);
      throw error;
    }
  };

export const getCodingMarketCenterPCP = async (credential) => {
    try {
      const response = await axios.post(`${API_URL}/coding/market_center_pcp`, credential);
      return response.data;
    } catch (error) {
      console.error('Error fetching patient:', error);
      throw error;
    }
  };

export const getCodingTaskTypes = async (credential) => {
    try {
      const response = await axios.post(`${API_URL}/coding/task_types`, credential);
      return response.data;
    } catch (error) {
      console.error('Error fetching patient:', error);
      throw error;
    }
  };
  
export const addCodingApolloDiseaseDetection = async (patientId, credential, diagnosis, note, timestampString) => {
  try {
    let postData = credential;
    postData['module_category'] = 'DiseaseDetection';
    postData['module_name'] = 'Coding';
    postData['module_item_key'] = diagnosis + "/" + timestampString;
    postData['module_item_object'] = {
      "PatientID": patientId,
      "DiagnosisCode": diagnosis.split('/')[0],
      "HCC": diagnosis.split('/')[1],
      "Description": note
    };
    const response = await axios.post(`${API_URL}/coding/patient_add_disease_detection/${patientId}`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};


export const deleteCodingApolloDiseaseDetection = async (credential, message) => {
  try {
    let postData = credential;
    postData['module_category'] = 'DiseaseDetection';
    postData['module_name'] = 'Coding';
    postData['module_item_key'] = message.module_item_key;
    const response = await axios.post(`${API_URL}/coding/patient_delete_disease_detection/`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const getCodingPatientMedicalRecords = async (credential) => {
  try {
    const response = await axios.post(`${API_URL}/coding/medical_record_search/`, credential);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};

export const getCodingDiagnosisAutosuggest = async (credential, searchText) => {
  try {
    let postData = credential;
    postData['search_text'] = searchText;
    const response = await axios.post(`${API_URL}/coding/diagnosis_auto_suggest/`, postData);
    return response.data;
  } catch (error) {
    console.error('Error fetching patient:', error);
    throw error;
  }
};