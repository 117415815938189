import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getMembershipMemberList = async (credential) => {
    try {
      const response = await axios.post(`${API_URL}/mms/member_list`, credential);
      return response.data;
    } catch (error) {
      console.error('Error fetching patient:', error);
      throw error;
    }
  };